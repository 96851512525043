<template>
  <a-tree-select
    :value="defaultValue"
    :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
    :label-in-value="true"
    :tree-data="treeData"
    :multiple="multiple"
    :allowClear="!multiple"
    :replace-fields="replaceFields"
    @change="change"
    placeholder="请选择群组"
    :disabled="disabled"
  ></a-tree-select>
</template>

<script>
import { getApplicationGroupList } from '@/api/application'
import { getDatabaseGroupList } from '@/api/database'
import { getDomainGroupList } from '@/api/domain'
import { getHypervisorGroupList } from '@/api/hypervisor'
import { getMiddlewareGroupList } from '@/api/middleware'
import { getNetworkDeviceGroupList } from '@/api/network-device'
import { getTerminalDeviceGroupList } from '@/api/terminal-device'
import { getOSGroupList } from '@/api/os'
import { getServerGroupList } from '@/api/server'
import { getSiteGroupList } from '@/api/site'
import { getSSLCertificateGroupList } from '@/api/ssl-certificate'
import { getStorageGroupList } from '@/api/storage'
import { getScriptGroupList } from '@/api/script'
import { getContainerizationGroupList } from '@/api/containerization'

export default {
  name: 'GroupSelect',
  props: {
    value: {
      type: [Array, Object]
    },
    sourceType: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      treeData: [],
      replaceFields: {
        children: 'children',
        title: 'name',
        value: 'id'
      },
      defaultValue: undefined
    }
  },
  computed: {
    getGroupListFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return getNetworkDeviceGroupList
        case 'terminal_device':
          return getTerminalDeviceGroupList
        case 'storage':
          return getStorageGroupList
        case 'server':
          return getServerGroupList
        case 'os':
          return getOSGroupList
        case 'middleware':
          return getMiddlewareGroupList
        case 'database':
          return getDatabaseGroupList
        case 'hypervisor':
          return getHypervisorGroupList
        case 'domain':
          return getDomainGroupList
        case 'site':
          return getSiteGroupList
        case 'ssl_certificate':
          return getSSLCertificateGroupList
        case 'application':
          return getApplicationGroupList
        case 'script':
          return getScriptGroupList
        case 'containerization':
          return getContainerizationGroupList
        default:
          return undefined
      }
    }
  },
  mounted () {
    if (this.value) {
      if (!this.multiple) this.defaultValue = this.format(this.value)
      else this.defaultValue = this.value.map(item => this.format(item))
    }
    this.fetch()
  },
  methods: {
    fetch () {
      this.getGroupListFunc().then(res => {
        this.treeData = res.data
      })
    },
    change (v) {
      if (v) {
        let value
        if (!this.multiple) value = this.unFormat(v)
        else value = v.map(item => this.unFormat(item))
        this.$emit('input', value)
      } else {
        this.$emit('input', undefined)
      }
    },
    unFormat (data) {
      return {
        name: data.label,
        id: data.value
      }
    },
    format (data) {
      return {
        label: data.name,
        value: data.id
      }
    }
  },
  watch: {
    sourceType () {
      this.fetch()
      if (this.multiple) this.change([])
      else this.change(undefined)
    },
    value (v) {
      if (v) {
        if (!this.multiple) this.defaultValue = this.format(v)
        else this.defaultValue = v.map(item => this.format(item))
      } else {
        this.defaultValue = v
      }
    }
  }
}
</script>

<style lang="less">
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  padding: 0;
  width: 0px !important;
  overflow: hidden;
  max-width: 0px !important;
}
</style>
