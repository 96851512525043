import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/terminal-devices'

export function getTerminalDevice (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateTerminalDevice (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteTerminalDevice (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getTerminalDeviceMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getTerminalDeviceHealthStatusList (id) {
  return request.get(`${urlPrefix}/${id}/health/statuses`)
}

export function getTerminalDeviceMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getTerminalDeviceMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getTerminalDeviceMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateTerminalDeviceMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getTerminalDeviceList (params) {
  return request.get(urlPrefix, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function createTerminalDevice (data) {
  return request.post(urlPrefix, data)
}

export function getTerminalDeviceCount (params) {
  return request.get(`${urlPrefix}/count`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function getTerminalDeviceAlertCount (params) {
  return request.get(`${urlPrefix}/alerts/count`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function getTerminalDeviceAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function syncTerminalDevice (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function exportTerminalDevice (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getTerminalDeviceImportTemplate () {
  return request.get(`${urlPrefix}/import/template`, { responseType: 'blob' })
}

export function importTerminalDevice (data) {
  return request.post(`${urlPrefix}/import`, data)
}

export function getTerminalDeviceGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateTerminalDeviceGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteTerminalDeviceGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getTerminalDeviceGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createTerminalDeviceGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getTerminalDeviceGroupCount (params) {
  return request.get(`${urlPrefix}/groups/count`, { params })
}

export function getTerminalDeviceHealthScore (id) {
  return request.get(`${urlPrefix}/${id}/health/score`)
}
