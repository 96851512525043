import request from '@/utils/request'

const urlPrefix = '/scripts'

export function getScript (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateScript (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteScript (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getScriptList (params) {
  return request.get(urlPrefix, { params })
}

export function createScript (data) {
  return request.post(urlPrefix, data)
}

export function updateScriptGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteScriptGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getScriptGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createScriptGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}
