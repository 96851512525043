import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/containerizations'

export function getContainerization (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateContainerization (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteContainerization (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getContainerizationMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getContainerizationMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getContainerizationMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getContainerizationMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateContainerizationMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getContainerizationMonitorMacro (id) {
  return request.get(`${urlPrefix}/${id}/monitor/macros`)
}

export function getContainerizationList (params) {
  return request.get(urlPrefix, { params })
}

export function createContainerization (data) {
  return request.post(urlPrefix, data)
}

export function getContainerizationCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function syncContainerization (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function monitorContainerization (data) {
  return request.post(`${urlPrefix}/monitor`, data)
}

export function exportContainerization (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getContainerizationGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateContainerizationGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteContainerizationGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getContainerizationGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createContainerizationGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}
